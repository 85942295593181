.service {
    width: 300px;
    height: 70%;
    text-align: center;
    background-color: rgb(200, 200, 200, 0.75);
    padding: 20px;
}

.image {
    text-align: center;
}

.image img {
    width: 40%;
}

.service h2 {
    font-family: Roboto, sans-serif;
}

.service > p {
    text-align: left;
}
